import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";

export const frontmatter = {
  title: "Día -05",
  week: "Semana 0",
  day: "08",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-08",
  path: "/cronologia/semana-0#dia-08-mar",
};
const Day08M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        {/*<ModDashedLine />*/}
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Bulgaria se suma a la lista de países contagiados.
        </ModCovidHighlight>
        <ModCovidHighlight>
          Malta declara su primer caso confirmado.
        </ModCovidHighlight>
        <ModCovidHighlight>
          Moldavia declara su primer caso confirmado.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          <strong>En España</strong>, hasta el momento se han registrado 589
          casos (159 más que el día anterior) y 17 fallecidos (2,9% de los casos
          positivos).
        </ModText>
        <ModImage
          src="/images/svg/08-03-spain.svg"
          alt="Casos confirmados en España"
        />
        <ModText>
          El <strong>Ministerio de Sanidad</strong> mantiene el escenario de
          contención (nivel 1). El Ministro de Sanidad, tras la reunión de
          seguimiento diaria, ha indicado que se van a reforzar los mecanismos
          de seguimiento y coordinación.
        </ModText>
        <ModText>
          El desglose de defunciones por comunidades autónomas es de 8
          fallecidos en madrid, 5 en el País vasco, 1 en Cataluña. y 1 en la
          Comunidad Valenciana
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day08M;
